import IconRelationships from '../public/static/icons/bundle/icon-relationships.svg'
import IconConversation from '../public/static/icons/bundle/icon-conversation.svg'
import IconHome from '../public/static/icons/bundle/icon-home.svg'
import IconJob from '../public/static/icons/bundle/icon-job.svg'
import IconApps from '../public/static/icons/bundle/icon-nav-apps.svg'

export const getIcon = (bundleName: string) => {
  switch (bundleName) {
    case 'apps':
      return IconConversation
    case 'connect':
      return IconRelationships
    case 'enterprise':
      return IconHome
    case 'spaces':
    case 'space':
      return IconApps
    case 'studio':
      return IconJob
  }
}

export const getTitle = (bundleName: string) => {
  switch (bundleName) {
    case 'apps':
      return 'Pong Apps'
    case 'connect':
      return 'Pong Connect'
    case 'enterprise':
      return 'Pong Enterprise'
    case 'spaces':
    case 'space':
      return 'Pong Spaces'
    case 'studio':
      return 'Pong Studio'
  }
}
