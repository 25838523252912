import { useEffect, useState } from 'react'
import styles from '../../styles/modules/TopNav.module.scss'
import { getIcon, getTitle } from '../../helpers/bundle'

export const TopNav: React.FC = ({}) => {
  const [bundleName, setBundleName] = useState('')
  useEffect(() => {
    const redirect = new window.URLSearchParams(window.location.search).get(
      'redirect'
    )
    if (redirect) {
      try {
        setBundleName(new URL(redirect).host.split('.')[0])
      } catch {
        // ignore bad redirect URL
      }
    }
  }, [])

  const IconComponent = getIcon(bundleName)
  return (
    <nav className={`sticky-top ${styles['top-nav']}`}>
      <span className={`${styles['bundle-icon']}`}>
        {IconComponent && <IconComponent />}
      </span>
      <div className={`${styles['app-title']}`}>{getTitle(bundleName)}</div>
    </nav>
  )
}
